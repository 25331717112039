/**
 * 全局文件
 */

export default {
  title: "Mmscm",
  key: "mmscm",
  clientId: 'mmscm_web', // 客户端id
  clientSecret: 'mmscm_web_secret', // 客户端密钥
  tenantMode: true, // 是否开启租户模式
  tenantId: "000000", // 管理组租户编号
  captchaMode: false, // 是否开启验证码模式
  switchMode: false, // 是否开启部门切换模式
  tokenTime: 3000,
  tokenHeader: 'Mmscm-Auth',
  logo: "M",
  indexTitle: 'Mmscm',
  //配置菜单的属性
  menu: {
    iconDefault: 'iconfont icon-caidan',
    props: {
      label: 'name',
      path: 'path',
      icon: 'source',
      children: 'children'
    }
  },
  //配置首页不可关闭
  isFirstPage: false,
  fistPage: {
    label: "首页",
    value: "/home/index",
    params: {},
    query: {},
    group: [],
    close: false
  },
}